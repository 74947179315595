<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12">
        <FormWaiverDisclaimerCrawford />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(DISCLAIMERS_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE, arguments[0])
          "
          :value="this[DISCLAIMERS_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]"
          :rules="['required']"
          label="Signature of claimant"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" class="d-flex align-center justify-end">
        <DateSpan label="Current date:" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getDisclaimersFormProps } from '@/utils/helpers/app/claimsCrawford';
import DateSpan from '@/components/typography/DateSpan';

const FormWaiverDisclaimerCrawford = () =>
  import('@/components/typography/FormWaiverDisclaimerCrawford');

export default {
  name: 'Disclaimers',
  mixins: [syncForm],
  components: {
    DateSpan,
    FormWaiverDisclaimerCrawford,
  },
  props: {
    ...getDisclaimersFormProps(),
  },
};
</script>

<style lang="scss" scoped>
.disclaimer-text {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #464646;
  &--red {
    color: #9a2d2e;
  }
}
</style>
