<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_NAME, arguments[0])"
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_NAME]"
          :rules="['required', 'crawfordLocationName']"
          label="Name of Facility"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="
            val => {
              this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY] &&
                handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE, null);
              handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY, val);
            }
          "
          :items="countriesOptions"
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]"
          :rules="['required']"
          placeholder="Country"
          label="Country"
          :solo="soloSelects"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="!this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]"
          :items="statesOptions[this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]]"
          @input="
            handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE, arguments[0])
          "
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE]"
          :rules="['required']"
          placeholder="State"
          label="State"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_CITY, arguments[0])
          "
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_CITY]"
          :rules="['required', 'crawfordCity']"
          label="City"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_ZIP, arguments[0])
          "
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_ZIP]"
          :rules="['required', 'crawfordZip']"
          label="Zip Code"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE1, arguments[0])
          "
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE1]"
          :rules="['required', 'crawfordAddressLine1']"
          label="Address Line 1"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE2, arguments[0])
          "
          :value="this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE2]"
          :rules="['crawfordAddressLine2']"
          label="Address Line 2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handlePhoneNumberInput(
              RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_WORK_PHONE,
              arguments[0]
            )
          "
          :value="formatPhoneNumber(this[RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_WORK_PHONE])"
          @keydown="preventNondigits(arguments[0])"
          :rules="['required', 'crawfordWorkPhone']"
          type="tel"
          label="Phone Number"
        >
          <template v-slot:prepend-inner>+1</template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, withCountryStateOptions, phoneNumberMethods } from '@/mixins';
import { getRiskLocationInfoFormProps } from '@/utils/helpers/app/claimsCrawford';

export default {
  name: 'RiskLocationInfo',
  mixins: [syncForm, withCountryStateOptions, phoneNumberMethods],
  props: {
    ...getRiskLocationInfoFormProps(),
    showAllLabels: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    soloSelects() {
      return !this.showAllLabels;
    },
  },
};
</script>
