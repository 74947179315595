<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CALLER_FIELD_ALIASES.CALLER_FIRST_NAME, arguments[0])"
          :value="this[CALLER_FIELD_ALIASES.CALLER_FIRST_NAME]"
          :rules="['required', 'crawfordFirstName']"
          label="First Name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CALLER_FIELD_ALIASES.CALLER_LAST_NAME, arguments[0])"
          :value="this[CALLER_FIELD_ALIASES.CALLER_LAST_NAME]"
          :rules="['required', 'crawfordLastName']"
          label="Last Name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(CALLER_FIELD_ALIASES.CALLER_EMAIL, arguments[0])"
          :value="this[CALLER_FIELD_ALIASES.CALLER_EMAIL]"
          :rules="['required', 'email', 'crawfordEmail']"
          type="email"
          label="Email"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handlePhoneNumberInput(CALLER_FIELD_ALIASES.CALLER_WORK_PHONE, arguments[0])"
          :value="formatPhoneNumber(this[CALLER_FIELD_ALIASES.CALLER_WORK_PHONE])"
          @keydown="preventNondigits(arguments[0])"
          :rules="['required', 'crawfordWorkPhone']"
          type="tel"
          label="Phone Number"
        >
          <template v-slot:prepend-inner>+1</template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection title="Are you an agent or the insured/client?" required>
          <v-radio-group
            @change="handleFieldInput(CALLER_FIELD_ALIASES.CALLER_TYPE, arguments[0])"
            :value="this[CALLER_FIELD_ALIASES.CALLER_TYPE]"
            :rules="['required']"
            row
          >
            <v-radio
              :value="this.selectOptions.callerTypes[0].value"
              :label="this.selectOptions.callerTypes[0].text"
            ></v-radio>
            <v-radio
              :value="this.selectOptions.callerTypes[1].value"
              :label="this.selectOptions.callerTypes[1].text"
            ></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, phoneNumberMethods } from '@/mixins';
import { getCallerInfoFormProps } from '@/utils/helpers/app/claimsCrawford';
import { callerTypes } from '@/utils/options';
import FormSection from '@/components/FormSection';

export default {
  name: 'CallerInfo',
  mixins: [syncForm, phoneNumberMethods],
  components: {
    FormSection,
  },
  props: {
    ...getCallerInfoFormProps(),
  },
  data() {
    return {
      selectOptions: {
        callerTypes,
      },
    };
  },
};
</script>
