<template>
  <v-form ref="form" class="claim-form">
    <div class="claim-form__section-wrapper">
      <FormGroup
        :title="`${isInjuryLossType ? 'Injury' : 'Loss'} and Policy Information`"
        titleTransform="none"
      >
        <LossAndPolicyInfo
          v-bind="lossAndPolicyInfoFormProps"
          @fieldInput="handleFormSectionFieldInput"
          :formIsInjuryOnly="formParams.formIsInjuryOnly"
          :isInjuryLossType="isInjuryLossType"
          :showAllLabels="showAllLabels"
        />
      </FormGroup>
    </div>
    <v-row>
      <v-col cols="12" md="12">
        <FormSection
          title="Is the injured person currently enrolled in any health insurance and/or other sports accident plan?"
        >
          <v-radio-group
            @change="
              handleFieldInput(
                INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN,
                arguments[0]
              )
            "
            :value="this[INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" :label="$t('ui.yes')"></v-radio>
            <v-radio :value="false" :label="$t('ui.no')"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <div class="claim-form__section-wrapper" v-if="healthInsuranceInfoFormProps.shown">
      <FormGroup
        class="claim-form__section claim-form__section--smallerTitle"
        :title="healthInsuranceInfoFormProps.shown ? 'Health Insurance Plan' : ''"
        titleTransform="capitalize"
      >
        <HealthInsuranceInfo
          v-bind="healthInsuranceInfoFormProps"
          :accidentPlan="this[INJURED_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN]"
          @fieldInput="handleFormSectionFieldInput"
          @addForm="handleAddHealthInsuranceForm"
          @deleteForm="handleDeleteHealthInsuranceForm"
        />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup
        title="Facility Location Information"
        titleTransform="none"
        subtitle="Where is the field, facility, camp, or other premises that the athlete was participating? This may match the address provided above, but can be different for off-premise incidents"
      >
        <v-row class="fnol-submit-view__footer-wrapper my-4 mt-md-6 mb-md-8" no-gutters>
          <v-col cols="12" class="fnol-submit-view__footer">
            <div class="d-flex flex-column flex-md-row justify-md-start">
              <v-btn @click="copyLossLocationToFacilityLocation">
                Same as {{ this.isInjuryLossType ? 'Injury' : 'Loss' }} Location
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <RiskLocationInfo
          v-bind="riskLocationInfoFormProps"
          @fieldInput="handleFormSectionFieldInput"
          :showAllLabels="showAllLabels"
        />
      </FormGroup>
    </div>
    <div v-if="isInjuryAndPropertyLossType" class="claim-form__section-wrapper">
      <FormGroup title="Property Information" titleTransform="none">
        <PropertyInfo
          v-bind="propertyInfoFormProps"
          @fieldInput="handleFormSectionFieldInput"
          :showAllLabels="showAllLabels"
        />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup
        title="Injured Information"
        titleTransform="none"
        subtitle="Please provide information on the injured participant"
      >
        <InjuredInfo
          v-bind="injuredInfoFormProps"
          @fieldInput="handleFormSectionFieldInput"
          :showAllLabels="showAllLabels"
        />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper">
      <FormGroup
        title="Submitter Information"
        titleTransform="none"
        subtitle="Please provide information about yourself for contact purposes"
      >
        <CallerInfo v-bind="callerInfoFormProps" @fieldInput="handleFormSectionFieldInput" />
      </FormGroup>
    </div>
    <div class="claim-form__section-wrapper my-0">
      <Disclaimers
        v-bind="disclaimersFormProps"
        :hiddenFields="formParams.hiddenFields"
        @claimsInfoClick="handleClaimsInfoDisclaimerClick"
        @fieldInput="handleFormSectionFieldInput"
      />
    </div>
  </v-form>
</template>

<script>
import { claimFormCrawford, syncForm, validateForm } from '@/mixins';
import FormGroup from '@/components/FormGroup';
import LossAndPolicyInfo from '@/components/forms/ClaimFormCrawford/partials/LossAndPolicyInfo';
import HealthInsuranceInfo from '@/components/forms/ClaimFormCrawford/partials/HealthInsuranceInfo';
import RiskLocationInfo from '@/components/forms/ClaimFormCrawford/partials/RiskLocationInfo';
import PropertyInfo from '@/components/forms/ClaimFormCrawford/partials/PropertyInfo';
import InjuredInfo from '@/components/forms/ClaimFormCrawford/partials/InjuredInfo';
import CallerInfo from '@/components/forms/ClaimFormCrawford/partials/CallerInfo';
import Disclaimers from '@/components/forms/ClaimFormCrawford/partials/Disclaimers';
import { LOSS_TYPES } from '@/utils/constants';

const FormSection = () => import('@/components/FormSection');

export default {
  name: 'ClaimFormCrawford',
  mixins: [claimFormCrawford, syncForm, validateForm],
  components: {
    FormGroup,
    LossAndPolicyInfo,
    HealthInsuranceInfo,
    RiskLocationInfo,
    PropertyInfo,
    InjuredInfo,
    CallerInfo,
    Disclaimers,
    FormSection,
  },
  props: {
    showAllLabels: Boolean,
  },
  methods: {
    handleFormSectionFieldInput({ field, value }) {
      this.handleFieldInput(field, value);
    },
    copyLossLocationToFacilityLocation() {
      const fieldMap = {
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_NAME]: this.LOSS_AND_POLICY_FIELD_ALIASES
          .LOSS_LOCATION_NAME,
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE1]: this
          .LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE1,
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_LINE2]: this
          .LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE2,
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_CITY]: this
          .LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_CITY,
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_STATE]: this
          .LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE,
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_ZIP]: this
          .LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_ZIP,
        [this.RISK_LOCATION_FIELD_ALIASES.RISK_LOCATION_ADDRESS_COUNTRY]: this
          .LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY,
      };

      Object.entries(fieldMap).forEach(([targetField, sourceField]) => {
        this.handleFieldInput(targetField, this[sourceField]);
      });
    },
    handleAddHealthInsuranceForm() {
      this.$emit('addHealthInsuranceForm');
    },
    handleDeleteHealthInsuranceForm(formKey) {
      this.$emit('deleteHealthInsuranceForm', formKey);
    },
  },
  computed: {
    isInjuryLossType() {
      return this[this.LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE] === LOSS_TYPES.INJURY;
    },
    isInjuryAndPropertyLossType() {
      return this[this.LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE] === LOSS_TYPES.BOTH;
    },
  },
};
</script>

<style lang="scss" scoped>
.claim-form__section-wrapper {
  &:nth-child(1n + 2) {
    margin-top: 45px;
  }
}
</style>
