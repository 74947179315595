import { render, staticRenderFns } from "./PropertyInfo.vue?vue&type=template&id=57c032ec&"
import script from "./PropertyInfo.vue?vue&type=script&lang=js&"
export * from "./PropertyInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import VRadioGroup from '@/components/HOC/VRadioGroup'
import { VRow } from 'vuetify/lib/components/VGrid';
import VSelect from '@/components/HOC/VSelect'
import VTextField from '@/components/HOC/VTextField'
installComponents(component, {VCol,VContainer,VRadio,VRadioGroup,VRow,VSelect,VTextField})
