var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_vm._l((_vm.healthInsuranceForms),function(subForm,subFormKey,orderIndex){return _c('section',{key:subFormKey,staticClass:"healthInsuranceInfoForm__section"},[_c('h3',{staticClass:"healthInsuranceInfoForm__sectionHeading"},[_vm._v("Plan "+_vm._s(orderIndex + 1))]),_c('div',{staticClass:"healthInsuranceInfoForm__sectionContent"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"new-password","value":subForm[_vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_COMPANY_NAME],"rules":['required'],"label":"Company Name"},on:{"input":function($event){return _vm.handleSubFormFieldInput(
                subFormKey,
                _vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_COMPANY_NAME,
                arguments[0]
              )}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"new-password","value":subForm[_vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_GROUP_NAME],"rules":['required'],"label":"Group Name"},on:{"input":function($event){return _vm.handleSubFormFieldInput(
                subFormKey,
                _vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_GROUP_NAME,
                arguments[0]
              )}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"new-password","value":subForm[_vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_HOLDERS_NAME],"rules":['required'],"label":"Policyholders Name"},on:{"input":function($event){return _vm.handleSubFormFieldInput(
                subFormKey,
                _vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_HOLDERS_NAME,
                arguments[0]
              )}}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"new-password","value":subForm[_vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_MEMBER_ID],"rules":['memberIdRule'],"label":"Member ID"},on:{"input":function($event){return _vm.handleSubFormFieldInput(
                subFormKey,
                _vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_MEMBER_ID,
                arguments[0]
              )}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"new-password","value":subForm[_vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_NUMBER],"rules":['required'],"label":"Policy Number"},on:{"input":function($event){return _vm.handleSubFormFieldInput(
                subFormKey,
                _vm.HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_NUMBER,
                arguments[0]
              )}}})],1)],1)],1),(orderIndex > 0)?_c('div',{staticClass:"healthInsuranceInfoForm__sectionFooter"},[_c('DeleteBtn',{attrs:{"reverse":""},on:{"click":function($event){return _vm.handleDeleteFormClick(subFormKey)}}},[_vm._v(" "+_vm._s(_vm.$t('ui.delete'))+" ")])],1):_vm._e()])}),_c('div',{staticClass:"healthInsuranceInfoForm__footer"},[(_vm.accidentPlan)?_c('div',{staticClass:"healthInsuranceInfoForm__footerControls"},[_c('v-btn',{staticClass:"healthInsuranceInfoForm__btn",attrs:{"text":""},on:{"click":_vm.handleAddFormClick}},[_vm._v(" + "+_vm._s(_vm.$t('ui.addAnotherPlan'))+" ")])],1):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }