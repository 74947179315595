<template>
  <v-container pa-0 fluid>
    <v-row v-if="!formIsInjuryOnly">
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE, arguments[0])"
          :items="selectOptions.lossTypes"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TYPE]"
          :rules="['required']"
          placeholder="Type of Loss"
          label="Type of Loss"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.POLICY_NUMBER, arguments[0])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.POLICY_NUMBER]"
          :rules="['maxlen15']"
          label="Policy Number"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.POLICY_STATE, arguments[0])"
          :items="Object.values(statesOptions).reduce((acc, states) => acc.concat(states), [])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.POLICY_STATE]"
          :rules="['required']"
          placeholder="Policy State"
          label="Policy State"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="
            val => {
              this[LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE] &&
                handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.GL_DESCRIPTION_CODE, null);
              handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE, val);
            }
          "
          :items="selectOptions.glCauseCodes"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE]"
          :rules="['required']"
          placeholder="General Liability Cause"
          label="General Liability Cause"
          :solo="soloSelects"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="!this[LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE]"
          :items="
            selectOptions.glDescriptionCodes[this[LOSS_AND_POLICY_FIELD_ALIASES.GL_CAUSE_CODE]]
          "
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.GL_DESCRIPTION_CODE, arguments[0])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.GL_DESCRIPTION_CODE]"
          :rules="['required']"
          placeholder="General Liability Description"
          label="General Liability Description"
          :solo="soloSelects"
        />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DATE, arguments[0])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DATE]"
          :hideDetails="false"
          :placeholder="`${isInjuryLossType ? 'Injury' : 'Loss'} Date`"
          :label="`${isInjuryLossType ? 'Injury' : 'Loss'} Date`"
          fullWidth
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <PhTimepicker
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TIME, arguments[0])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_TIME]"
          :hideDetails="false"
          :placeholder="`${isInjuryLossType ? 'Injury' : 'Loss'} Time`"
          fullWidth
          required
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-textarea
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DESCRIPTION, arguments[0])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_DESCRIPTION]"
          :rules="['required', 'crawfordLossDescription']"
          :label="`${isInjuryLossType ? 'Injury' : 'Loss'} Description`"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_NAME, arguments[0])"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_NAME]"
          :rules="['required', 'crawfordLocationName']"
          :label="
            `Name of location where accident/injury${isInjuryLossType ? '' : '/loss'} occurred`
          "
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-select
          @input="
            handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_TYPE, arguments[0])
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_TYPE]"
          :items="selectOptions.eventTypes"
          placeholder="Event Type"
          label="Event Type"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(
              LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_NAME_AND_TYPE,
              arguments[0]
            )
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_EVENT_NAME_AND_TYPE]"
          :rules="['maxlen250']"
          label="Event Name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="
            val => {
              this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY] &&
                handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE, null);
              handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY, val);
            }
          "
          :items="countriesOptions"
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY]"
          :rules="['required']"
          placeholder="Country"
          label="Country"
          :solo="soloSelects"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="!this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY]"
          :items="statesOptions[this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_COUNTRY]]"
          @input="
            handleFieldInput(
              LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE,
              arguments[0]
            )
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_STATE]"
          :rules="['required']"
          placeholder="State"
          label="State"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_CITY, arguments[0])
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_CITY]"
          :rules="['required', 'crawfordCity']"
          label="City"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_ZIP, arguments[0])
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_ZIP]"
          :rules="['required', 'crawfordZip']"
          label="Zip Code"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(
              LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE1,
              arguments[0]
            )
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE1]"
          :rules="['required', 'crawfordAddressLine1']"
          label="Address Line 1"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(
              LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE2,
              arguments[0]
            )
          "
          :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_ADDRESS_LINE2]"
          :rules="['crawfordAddressLine2']"
          label="Address Line 2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <FormSection title="Did the accident occur on the premises?" required>
          <v-radio-group
            @change="
              handleFieldInput(
                LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_IS_ON_PREMISSES,
                arguments[0]
              )
            "
            :value="this[LOSS_AND_POLICY_FIELD_ALIASES.LOSS_LOCATION_IS_ON_PREMISSES]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, withCountryStateOptions } from '@/mixins';
import { getLossAndPolicyInfoFormProps } from '@/utils/helpers/app/claimsCrawford';
import PhDatepicker from '@/components/pickers/PhDatepicker';
import PhTimepicker from '@/components/pickers/PhTimepicker';
import FormSection from '@/components/FormSection';
import { lossTypes, glCauseCodes, glDescriptionCodes, eventTypes } from '@/utils/options';

export default {
  name: 'LossAndPolicyInfo',
  mixins: [syncForm, withCountryStateOptions],
  components: {
    PhDatepicker,
    PhTimepicker,
    FormSection,
  },
  props: {
    ...getLossAndPolicyInfoFormProps(),
    formIsInjuryOnly: Boolean,
    isInjuryLossType: Boolean,
    showAllLabels: Boolean,
  },
  data() {
    return {
      selectOptions: {
        lossTypes,
        glCauseCodes,
        glDescriptionCodes,
        eventTypes,
      },
    };
  },
  computed: {
    soloSelects() {
      return !this.showAllLabels;
    },
  },
};
</script>
