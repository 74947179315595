<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(PROPERTY_FIELD_ALIASES.PROPERTY_DESCRIPTION, arguments[0])"
          :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_DESCRIPTION]"
          :rules="['required', 'crawfordInjuredDoing']"
          label="Property Description"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="
            handleFieldInput(PROPERTY_FIELD_ALIASES.PROPERTY_DAMAGE_DESCRIPTION, arguments[0])
          "
          :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_DAMAGE_DESCRIPTION]"
          :rules="['required', 'crawfordInjuredDoing']"
          label="Damage Description"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <FormGroup title="Property Owner" titleTransform="none">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="12" md="12">
                <FormSection title="Was the property owned by a person or a company?" required>
                  <v-radio-group
                    @change="
                      handleFieldInput(PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE, arguments[0])
                    "
                    :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE]"
                    :rules="['required']"
                    row
                  >
                    <v-radio
                      :value="this.selectOptions.propertyOwnerTypes[0].value"
                      :label="this.selectOptions.propertyOwnerTypes[0].text"
                    ></v-radio>
                    <v-radio
                      :value="this.selectOptions.propertyOwnerTypes[1].value"
                      :label="this.selectOptions.propertyOwnerTypes[1].text"
                    ></v-radio>
                  </v-radio-group>
                </FormSection>
              </v-col>
            </v-row>
            <v-row v-if="isCompanyOwned">
              <v-col cols="12" md="12">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_COMPANY_NAME,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_COMPANY_NAME]"
                  :rules="['required', 'crawfordLocationName']"
                  label="Company Name"
                />
              </v-col>
            </v-row>
            <v-row v-if="isPersonallyOwned">
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_FIRST_NAME,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_FIRST_NAME]"
                  :rules="['required', 'crawfordFirstName']"
                  label="First Name"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_LAST_NAME,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_PERSON_LAST_NAME]"
                  :rules="['required', 'crawfordLastName']"
                  label="Last Name"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  @input="
                    val => {
                      this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY] &&
                        handleFieldInput(PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_STATE, null);
                      handleFieldInput(PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY, val);
                    }
                  "
                  :items="countriesOptions"
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY]"
                  :rules="['required']"
                  placeholder="Country"
                  label="Country"
                  :solo="soloSelects"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  :disabled="!this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY]"
                  :items="
                    statesOptions[this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_COUNTRY]]
                  "
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_STATE,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_STATE]"
                  :rules="['required']"
                  placeholder="State"
                  label="State"
                  :solo="soloSelects"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_CITY,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_CITY]"
                  :rules="['required', 'crawfordCity']"
                  label="City"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_ZIP,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_ZIP]"
                  :rules="['required', 'crawfordZip']"
                  label="Zip Code"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE1,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE1]"
                  :rules="['required', 'crawfordAddressLine1']"
                  label="Address Line 1"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handleFieldInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE2,
                      arguments[0]
                    )
                  "
                  :value="this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_ADDRESS_LINE2]"
                  :rules="['crawfordAddressLine2']"
                  label="Address Line 2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handlePhoneNumberInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_HOME_PHONE,
                      arguments[0]
                    )
                  "
                  :value="formatPhoneNumber(this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_HOME_PHONE])"
                  @keydown="preventNondigits(arguments[0])"
                  :rules="['required', 'crawfordHomePhone']"
                  type="tel"
                  label="Home Phone Number"
                >
                  <template v-slot:prepend-inner>+1</template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  autocomplete="new-password"
                  @input="
                    handlePhoneNumberInput(
                      PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_WORK_PHONE,
                      arguments[0]
                    )
                  "
                  :value="formatPhoneNumber(this[PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_WORK_PHONE])"
                  @keydown="preventNondigits(arguments[0])"
                  :rules="['required', 'crawfordWorkPhone']"
                  type="tel"
                  label="Work Phone Number"
                >
                  <template v-slot:prepend-inner>+1</template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </FormGroup>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, withCountryStateOptions, phoneNumberMethods } from '@/mixins';
import { getPropertyInfoFormProps } from '@/utils/helpers/app/claimsCrawford';
import FormGroup from '@/components/FormGroup';
import FormSection from '@/components/FormSection';
import { propertyOwnerTypes } from '@/utils/options';
import { PROPERTY_OWNER_TYPES } from '@/utils/constants';

export default {
  name: 'PropertyInfo',
  mixins: [syncForm, withCountryStateOptions, phoneNumberMethods],
  components: {
    FormGroup,
    FormSection,
  },
  props: {
    ...getPropertyInfoFormProps(),
    showAllLabels: Boolean,
  },
  data() {
    return {
      selectOptions: {
        propertyOwnerTypes,
      },
    };
  },
  computed: {
    isCompanyOwned() {
      return this[this.PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE] === PROPERTY_OWNER_TYPES.COMPANY;
    },
    isPersonallyOwned() {
      return this[this.PROPERTY_FIELD_ALIASES.PROPERTY_OWNER_TYPE] === PROPERTY_OWNER_TYPES.PERSON;
    },
    soloSelects() {
      return !this.showAllLabels;
    },
  },
};
</script>
