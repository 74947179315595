<template>
  <v-container pa-0 fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_FIRST_NAME, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_FIRST_NAME]"
          :rules="['required', 'crawfordFirstName']"
          label="First Name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_LAST_NAME, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_LAST_NAME]"
          :rules="['required', 'crawfordLastName']"
          label="Last Name"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          @input="
            val => {
              this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY] &&
                handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_STATE, null);
              handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY, val);
            }
          "
          :items="countriesOptions"
          :value="this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY]"
          :rules="['required']"
          placeholder="Country"
          label="Country"
          :solo="soloSelects"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :disabled="!this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY]"
          :items="statesOptions[this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_COUNTRY]]"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_STATE, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_STATE]"
          :rules="['required']"
          placeholder="State"
          label="State"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_CITY, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_CITY]"
          :rules="['required', 'crawfordCity']"
          label="City"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_ZIP, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_ZIP]"
          :rules="['required', 'crawfordZip']"
          label="Zip Code"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE1, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE1]"
          :rules="['required', 'crawfordAddressLine1']"
          label="Address Line 1"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE2, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_ADDRESS_LINE2]"
          :rules="['crawfordAddressLine2']"
          label="Address Line 2"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <PhDatepicker
          fullWidth
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_DOB, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_DOB]"
          :hide-details="false"
          placeholder="Date of Birth"
          required
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_GENDER, arguments[0])"
          :items="selectOptions.genders"
          :value="this[INJURED_FIELD_ALIASES.INJURED_GENDER]"
          :rules="['required']"
          placeholder="Gender"
          label="Gender"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-autocomplete
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_BODY_PART_CODE, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_BODY_PART_CODE]"
          :items="selectOptions.bodyParts"
          :rules="['required']"
          placeholder="Injured Body Part"
          label="Injured Body Part"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-select
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_INJURY_CODE, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_INJURY_CODE]"
          :items="selectOptions.injuryTypes"
          :rules="['required']"
          placeholder="Injury"
          label="Injury"
          :solo="soloSelects"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_INJURY_DESCRIPTION, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_INJURY_DESCRIPTION]"
          :rules="['required', 'crawfordInjuryDescription']"
          label="Injury Description"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_INJURED_DOING, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_INJURED_DOING]"
          :rules="['required', 'crawfordInjuredDoing']"
          label="Activity Causing Injury"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <FormSection
          title="Was an ambulance required to transport the injured person to the hospital?"
          required
        >
          <v-radio-group
            @change="
              handleFieldInput(
                INJURED_FIELD_ALIASES.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE,
                arguments[0]
              )
            "
            :value="this[INJURED_FIELD_ALIASES.INJURED_TAKEN_TO_HOSPITAL_BY_AMBULANCE]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
      <v-col cols="12" md="6">
        <FormSection title="Did the injury result in death?" required>
          <v-radio-group
            @change="
              handleFieldInput(INJURED_FIELD_ALIASES.INJURED_INJURY_RESULT_IN_DEATH, arguments[0])
            "
            :value="this[INJURED_FIELD_ALIASES.INJURED_INJURY_RESULT_IN_DEATH]"
            :rules="['requiredBoolean']"
            row
          >
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </FormSection>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_MEMBER_ID, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_MEMBER_ID]"
          :rules="['maxlen250']"
          label="Member ID"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_STATE_ASSOCIATION, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_STATE_ASSOCIATION]"
          :rules="['maxlen250']"
          label="State Association"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_NAME_OF_LEAGUE, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_NAME_OF_LEAGUE]"
          :rules="['maxlen250']"
          label="League Name"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          autocomplete="new-password"
          @input="handleFieldInput(INJURED_FIELD_ALIASES.INJURED_NAME_OF_TEAM, arguments[0])"
          :value="this[INJURED_FIELD_ALIASES.INJURED_NAME_OF_TEAM]"
          :rules="['maxlen250']"
          label="Name of Team"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { syncForm, withCountryStateOptions } from '@/mixins';
import { getInjuredInfoFormProps } from '@/utils/helpers/app/claimsCrawford';
import { genders, bsiInjuryCodes, bsiBodyParts } from '@/utils/options';
import FormSection from '@/components/FormSection';
import PhDatepicker from '@/components/pickers/PhDatepicker';

export default {
  name: 'InjuredInfo',
  mixins: [syncForm, withCountryStateOptions],
  components: {
    FormSection,
    PhDatepicker,
  },
  props: {
    ...getInjuredInfoFormProps(),
    showAllLabels: Boolean,
  },
  data() {
    return {
      selectOptions: {
        genders,
        bodyParts: bsiBodyParts,
        injuryTypes: bsiInjuryCodes,
      },
    };
  },
  computed: {
    soloSelects() {
      return !this.showAllLabels;
    },
  },
};
</script>
