<template>
  <v-container pa-0 fluid>
    <section
      v-for="(subForm, subFormKey, orderIndex) in healthInsuranceForms"
      :key="subFormKey"
      class="healthInsuranceInfoForm__section"
    >
      <h3 class="healthInsuranceInfoForm__sectionHeading">Plan {{ orderIndex + 1 }}</h3>
      <div class="healthInsuranceInfoForm__sectionContent">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              autocomplete="new-password"
              @input="
                handleSubFormFieldInput(
                  subFormKey,
                  HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_COMPANY_NAME,
                  arguments[0]
                )
              "
              :value="subForm[HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_COMPANY_NAME]"
              :rules="['required']"
              label="Company Name"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              autocomplete="new-password"
              @input="
                handleSubFormFieldInput(
                  subFormKey,
                  HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_GROUP_NAME,
                  arguments[0]
                )
              "
              :value="subForm[HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_GROUP_NAME]"
              :rules="['required']"
              label="Group Name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              autocomplete="new-password"
              @input="
                handleSubFormFieldInput(
                  subFormKey,
                  HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_HOLDERS_NAME,
                  arguments[0]
                )
              "
              :value="subForm[HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_HOLDERS_NAME]"
              :rules="['required']"
              label="Policyholders Name"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              autocomplete="new-password"
              @input="
                handleSubFormFieldInput(
                  subFormKey,
                  HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_MEMBER_ID,
                  arguments[0]
                )
              "
              :value="subForm[HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_MEMBER_ID]"
              :rules="['memberIdRule']"
              label="Member ID"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              autocomplete="new-password"
              @input="
                handleSubFormFieldInput(
                  subFormKey,
                  HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_NUMBER,
                  arguments[0]
                )
              "
              :value="subForm[HEALTH_FIELD_ALIASES.HEALTH_INSURANCE_POLICY_NUMBER]"
              :rules="['required']"
              label="Policy Number"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="orderIndex > 0" class="healthInsuranceInfoForm__sectionFooter">
        <DeleteBtn @click="handleDeleteFormClick(subFormKey)" reverse>
          {{ $t('ui.delete') }}
        </DeleteBtn>
      </div>
    </section>
    <div class="healthInsuranceInfoForm__footer">
      <div class="healthInsuranceInfoForm__footerControls" v-if="accidentPlan">
        <v-btn @click="handleAddFormClick" class="healthInsuranceInfoForm__btn" text>
          + {{ $t('ui.addAnotherPlan') }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { syncForm } from '@/mixins';
import { getHealthInsuranceInfoFormProps } from '@/utils/helpers/app/claimsCrawford';

const DeleteBtn = () => import('@/components/buttons/DeleteBtn');

export default {
  name: 'HealthInsuranceInfo',
  mixins: [syncForm],
  components: {
    DeleteBtn,
  },
  props: {
    ...getHealthInsuranceInfoFormProps(),
    accidentPlan: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleAddFormClick() {
      this.$emit('addForm');
    },
    handleDeleteFormClick(formKey) {
      this.$emit('deleteForm', formKey);
    },
    handleSubFormFieldInput(subFormKey, field, value) {
      return this.handleFieldInput(`healthInsuranceForms.${subFormKey}.${field}`, value);
    },
  },
};
</script>

<style lang="scss" scoped>
.healthInsuranceInfoForm__section {
  &:nth-child(1n + 2) {
    margin-top: 15px;
  }
}
.healthInsuranceInfoForm__sectionHeading {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #004990;
}
.healthInsuranceInfoForm__footerDisclaimer {
  margin: 25px 0 40px 0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.7;
  color: #5c636c;
}
.healthInsuranceInfoForm__sectionFooter,
.healthInsuranceInfoForm__footerControls {
  margin: 0px -21px;
}
</style>
